<template>
  <span
    v-html="
      require(`../../assets/icons/${
        type !== undefined ? type : 'global'
      }/${name}.svg`)
    "
    :style="color !== undefined ? `color:${color}` : ''"
    :class="classData"
  />
</template>

<script>
//import LogoSVG from "@/assets/icons/global/book.svg";

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: String,
    type: String,
    class: String,
  },
  components: { },
  data() {
    return {
      classData: this.class !== undefined ? "icon " + this.class : "icon",
    };
  },
};
</script>
